import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getValidateTronAddress, ValidateInputs } from "../../helper/helper";
import { ChainIdEnum, NetworkEnum } from "../../helper/helper";
import { ethers } from "ethers";
import { isValidAddress } from "../../helper/evm.helper";
import { validate } from "bitcoin-address-validation";

const AddToken = () => {
  const [formData, setFormData] = useState({
    address: "",
    chainId: "",
    network: "",
    symbol: "",
    code: "",
    minWithdraw: 0,
    decimal: 0,
    minDeposit: 0,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    // Set chainId based on network selection
    if (name === "network") {
      const chainIdMap = {
        [NetworkEnum.POLYGON]: ChainIdEnum.POLYGON,
        [NetworkEnum.BINANCE_SMART_CHAIN]: ChainIdEnum.BINANCE_SMART_CHAIN,
        [NetworkEnum.ETHEREUM]: ChainIdEnum.ETHEREUM,
        [NetworkEnum.TRON]: ChainIdEnum.TRON,
        [NetworkEnum.BITCOIN]: ChainIdEnum.BITCOIN,
      };
      updatedFormData.chainId = chainIdMap[value] || "";
    }

    setFormData(updatedFormData);
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validateForm = async () => {
    const newErrors = {};

    if (!formData?.address?.trim()) {
      newErrors.address = "Address is required";
    }

    // const isEvmAddressCondition = formData.address.length === 42 && formData.address[0] === "0" && formData.address[1] === "x";
    // const isTronAddressCondition = formData.address.length === 34 && formData.address[0] === "T";

    const isEVMNetworkCondition = formData?.network === NetworkEnum.POLYGON || formData?.network === NetworkEnum.BINANCE_SMART_CHAIN || formData?.network === NetworkEnum.ETHEREUM;
    const isTronNetworkCondition = formData?.network === NetworkEnum.TRON;
    const isBTCNetworkCondition = formData?.network === NetworkEnum.BITCOIN;

    const isEvmAddress = await isValidAddress(formData?.address);
    const isTronAddress = await getValidateTronAddress(formData?.address);
    const isBTCAddress = await validate(formData?.address);
    console.log("isBTCAddress", isBTCAddress)

    console.log("isEvmAddress", isEvmAddress)
    console.log("isTronAddress",isTronAddress)

    console.log("")

    if (isEvmAddress) {
      console.log("56")
      if (!isEVMNetworkCondition) {
        newErrors.network = "Invalid EVM network";
      }
    }
    
    if (isEVMNetworkCondition) {
      console.log("78")
      if (!isEvmAddress) {
        newErrors.address = "EVM address not exist";
      }
    }

    if (isTronAddress) {
      if (!isTronNetworkCondition) {
        console.log("12")
        newErrors.network = "Invalid Tron network";
      }
    }

    if (isTronNetworkCondition) {
      if (!isTronAddress) {
        console.log("123")
        newErrors.address = "Tron address not exist";
      }
    }

    if (isBTCAddress) {
      if (!isBTCNetworkCondition) {
        console.log("12")
        newErrors.network = "Invalid BTC network";
      }
    }

    if (isBTCNetworkCondition) {
      if (!isBTCAddress) {
        console.log("123")
        newErrors.address = "BTC address not exist";
      }
    }

    if (!formData?.network?.trim()) {
      newErrors.network = "Network is required";
    }

    if (!formData?.symbol?.trim()) {
      newErrors.symbol = "Symbol is required";
    }

    if (!formData?.code?.trim()) {
      newErrors.code = "Code is required";
    }

    if (formData?.decimal === 0) {
      newErrors.decimal = "Decimal is required";
    }

    if (formData?.minDeposit === 0) {
      newErrors.minDeposit = "Minimum Deposit is required";
    }

    if (formData?.minWithdraw === 0) {
      newErrors.minWithdraw = "Minimum Withdraw is required";
    }

    if (formData?.decimal < 0) {
      newErrors.decimal = "Decimal should be greater than zero";
    }

    if (formData?.minDeposit < 0) {
      newErrors.minDeposit = "Minimum Deposit should be greater than zero";
    }

    if (formData?.minWithdraw < 0) {
      newErrors.minWithdraw = "Minimum Withdraw should be greater than zero";
    }

    setErrors(newErrors);
    console.log("length: " ,Object.keys(newErrors))
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleValidation = await validateForm();
    console.log("handleValidation", handleValidation);
    if (!handleValidation) {
      console.log(
      "gf")
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/token/add`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("Token added successfully!");
      navigate("/withdrawal-limit");
    } catch (error) {
      console.error("Error adding token:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Add Token</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <>
                        <div className="form-group">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            name="address"
                            className={`form-control ${errors.address ? "is-invalid" : ""}`}
                            value={formData?.address}
                            onChange={handleChange}
                            placeholder="Enter address"
                            maxLength={100}
                          />
                          {errors.address && (
                            <div className="invalid-feedback">
                              {errors.address}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="network">Network</label>
                          <select
                            name="network"
                            className={`form-control ${errors.network ? "is-invalid" : ""}`}
                            value={formData?.network}
                            onChange={handleChange}
                          >
                            <option value="">Select Network</option>
                            {Object.entries(NetworkEnum).map(([key, value]) => (
                              <option key={key} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                          {errors.network && (
                            <div className="invalid-feedback">
                              {errors.network}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="symbol">Symbol</label>
                          <input
                            type="text"
                            name="symbol"
                            className={`form-control ${errors.symbol ? "is-invalid" : ""}`}
                            value={formData?.symbol}
                            onChange={handleChange}
                            placeholder="Enter symbol"
                            maxLength={50}
                          />
                          {errors.symbol && (
                            <div className="invalid-feedback">
                              {errors.symbol}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="code">Code</label>
                          <input
                            type="text"
                            name="code"
                            className={`form-control ${
                              errors.code ? "is-invalid" : ""
                            }`}
                            value={formData?.code}
                            onChange={handleChange}
                            placeholder="Enter code"
                            maxLength={50}
                          />
                          {errors.code && (
                            <div className="invalid-feedback">
                              {errors.code}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="decimal">Decimal</label>
                          <input
                            type="number"
                            name="decimal"
                            className={`form-control ${errors.decimal ? "is-invalid" : ""}`}
                            value={formData?.decimal}
                            onChange={handleChange}
                            placeholder="Enter decimal"
                            maxLength={50}
                          />
                          {errors.decimal && (
                            <div className="invalid-feedback">
                              {errors.decimal}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="minWithdraw">Min Withdrawal</label>
                          <input
                            type="number"
                            name="minWithdraw"
                            className={`form-control ${errors.minWithdraw ? "is-invalid" : ""}`}
                            value={formData?.minWithdraw}
                            onChange={handleChange}
                            placeholder="Enter minimum withdraw amount"
                            maxLength={50}
                          />
                          {errors.minWithdraw && (
                            <div className="invalid-feedback">
                              {errors.minWithdraw}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="minDeposit">Min Deposit</label>
                          <input
                            type="number"
                            name="minDeposit"
                            className={`form-control ${errors.minDeposit ? "is-invalid" : ""}`}
                            value={formData?.minDeposit}
                            onChange={handleChange}
                            placeholder="Enter minimum deposit amount"
                            maxLength={50}
                          />
                          {errors.minDeposit && (
                            <div className="invalid-feedback">
                              {errors.minDeposit}
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToken;
