import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getListErrors, ValidateInputs } from "../../helper/helper";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "../../components/Loader";

const UpdateMerchant = () => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    isAccountSuspend: false,
    name: "",
    email: "",
    countryCode: "",
    contactNumber: "",
    platformName: "",
    platformCategory: "",
    location: "",
    description: "",
    fullContactNumber: "",
  });
  const [errors, setErrors] = useState({});
  const { userId } = useParams();
  const navigate = useNavigate();

  console.log("formData ---------> : ", formData);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/merchants/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response.data);
        setFormData({
          isAccountSuspend: response?.data?.user?.isAccountSuspend,
          name: response?.data?.user?.name,
          email: response?.data?.user?.email,
          countryCode: response?.data?.user?.countryCode,
          contactNumber: response?.data?.user?.contactNumber,
          platformName: response?.data?.user?.platformName,
          platformCategory: response?.data?.user?.platformCategory,
          location: response?.data?.user?.location,
          description: response?.data?.user?.description,
          fullContactNumber:
            response?.data?.user?.countryCode +
            response?.data?.user?.contactNumber,
        });
      } catch (error) {
        console.error("Error fetching merchant details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Convert "true"/"false" string values to actual boolean values
    const newValue =
      value === "true" ? true : value === "false" ? false : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
    const updatedInputs = ValidateInputs(name, newValue, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact number is required";
    } else if (!/^\d{8,15}$/.test(formData.contactNumber)) {
      toast.error("Contact number is invalid");
      newErrors.contactNumber = "Contact number is invalid";
    }

    if (!formData.location.trim()) {
      newErrors.location = "Location is required";
    }
    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePhoneChange = (value, country) => {
    console.log("value is : ", value);
    console.log("country is : ", country);

    const dialCode = country?.dialCode ? `+${country?.dialCode}` : "";

    const rawNumber = value.replace(country?.dialCode, "").trim();

    setFormData((prevData) => ({
      ...prevData,
      countryCode: dialCode, // Store the country code
      contactNumber: rawNumber, // Store the contact number
      fullContactNumber: dialCode + rawNumber,
    }));
  };

  const handlePhoneBlur = () => {
    if (!formData.contactNumber) {
      setFormData((prevData) => ({
        ...prevData,
        countryCode: "",
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_URL}/merchants/update-user-profile`,
        { id: userId, ...formData },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Passing token in headers
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Merchant details updated successfully!");
      navigate("/merchants");
    } catch (error) {
      console.error("Error updating merchants details:", error);
      toast.error(getListErrors(error?.response?.data?.message));
    } finally {
      setIsLoading(false);
    }
  };

  console.log("Payload being sent:", { id: userId, ...formData });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Merchant Management</h1>
              </div>
            </div>
          </div>
          {isLoading == true ? (
            <Loader />
          ) : (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <form id="quickForm" onSubmit={handleFormSubmit}>
                      <div className="card-body">
                        {user && user?.user && (
                          <>
                            <div
                              className="form-group"
                              style={{
                                color:
                                  formData.isAccountSuspend == false
                                    ? "#52eb52"
                                    : "#ed5050",
                              }}
                            >
                              <label htmlFor="isAccountSuspend">
                                Is User Account Suspended
                              </label>
                              <select
                                name="isAccountSuspend"
                                className={`form-control ${
                                  errors.isAccountSuspend ? "is-invalid" : ""
                                }`}
                                value={
                                  formData.isAccountSuspend ? "true" : "false"
                                }
                                onChange={handleInputChange}
                              >
                                <option value="false">False</option>
                                <option value="true">True</option>
                              </select>
                              {errors.isAccountSuspend && (
                                <div className="invalid-feedback">
                                  {errors.isAccountSuspend}
                                </div>
                              )}
                            </div>

                            <div className="form-group">
                              <label htmlFor="name">Name</label>
                              <input
                                type="text"
                                name="name"
                                className={`form-control ${
                                  errors.name ? "is-invalid" : ""
                                }`}
                                placeholder="Enter name"
                                value={formData.name}
                                onChange={handleInputChange}
                                maxLength={50}
                              />
                              {errors.name && (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="email">Email</label>
                              <input
                                type="text"
                                name="email"
                                className={`form-control ${
                                  errors.email ? "is-invalid" : ""
                                }`}
                                placeholder="Enter email"
                                value={formData.email}
                                onChange={handleInputChange}
                                readOnly
                              />
                              {/* {errors.email && (
                                <div className="invalid-feedback">
                                  {errors.email}
                                </div>
                              )} */}
                            </div>
                            <div className="form-group">
                              <label htmlFor="contactNumber">
                                Contact Number
                              </label>

                              <PhoneInput
                                country=""
                                enableSearch={true}
                                placeholder="Enter Contact Number"
                                onKeyDown={(event) => {
                                  if (
                                    event.key == "Backspace" &&
                                    formData?.contactNumber == ""
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={handlePhoneChange}
                                onBlur={handlePhoneBlur}
                                value={formData?.fullContactNumber}
                                inputStyle={{
                                  width: "100%",
                                  paddingLeft: "50px",
                                  paddingRight: "15px",
                                  borderRadius: "5px",
                                  height: "43px",
                                  border: "1px solid #ccc",
                                  fontSize: "14px",
                                }}
                                inputClass={`form-control ${
                                  errors.contactNumber ? "is-invalid" : ""
                                }`}
                              />
                              {errors.contactNumber && (
                                <div className="invalid-feedback">
                                  {errors.contactNumber}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">PlatForm Name</label>
                              <input
                                type="text"
                                name="platformName"
                                className={`form-control ${
                                  errors.platformName ? "is-invalid" : ""
                                }`}
                                placeholder="Enter platformName"
                                value={formData.platformName}
                                onChange={handleInputChange}
                                readOnly
                              />
                              {/* {errors.platformName && (
                                <div className="invalid-feedback">
                                  {errors.platformName}
                                </div>
                              )} */}
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">Platform Category</label>
                              <input
                                type="text"
                                name="platformCategory"
                                className={`form-control ${
                                  errors.platformCategory ? "is-invalid" : ""
                                }`}
                                placeholder="Enter platformCategory"
                                value={formData.platformCategory}
                                onChange={handleInputChange}
                                readOnly
                              />
                              {/* {errors.platformCategory && (
                                <div className="invalid-feedback">
                                  {errors.platformCategory}
                                </div>
                              )} */}
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">Location</label>
                              <input
                                type="text"
                                name="location"
                                className={`form-control ${
                                  errors.location ? "is-invalid" : ""
                                }`}
                                placeholder="Enter location"
                                value={formData.location}
                                onChange={handleInputChange}
                                maxLength={100}
                              />
                              {errors.location && (
                                <div className="invalid-feedback">
                                  {errors.location}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">Description</label>
                              <textarea
                                type="text"
                                name="description"
                                className={`form-control ${
                                  errors.description ? "is-invalid" : ""
                                }`}
                                placeholder="Enter description"
                                value={formData.description}
                                onChange={handleInputChange}
                                maxLength={500}
                              />
                              {errors.description && (
                                <div className="invalid-feedback">
                                  {errors.description}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="card-footer">
                        <button type="submit" className="btn btn-primary">
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateMerchant;
