import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomPagination from "../../components/Pagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { exportToCSV } from "../../helper/helper";
import Loader from "../../components/Loader";

const WalletUser = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_EXTENSION_API_URL}/users/list`,
        {
          params: {
            pageNo: currentPage,
            limitVal: 10,
            search: searchQuery,
          },
        }
      );
      console.log("fd", response?.data?.data[0]?.evmWallet?.address);
      setUsers(response?.data?.data);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const copyToClipboard = (address) => {
    if (address) {
      navigator?.clipboard
        ?.writeText(address)
        .then(() => {
          toast.success("Address copied to clipboard!");
        })
        .catch((error) => {
          toast.error("Failed to copy address.");
          console.error("Error copying to clipboard:", error);
        });
    } else {
      toast.warning("No address available to copy.");
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, {
      head: [["S.N.", "Name", "Email", "Public Address"]],
      body: users.map((user, index) => [
        index + 1,
        user?.name || "",
        user?.email || "",
        user?.evmWallet?.address || "",
      ]),
    });
    doc.save("users.pdf");
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      users.map((user, index) => ({
        S_N: index + 1,
        Name: user?.name || "",
        Email: user?.email || "",
        Public_Address: user?.evmWallet?.address || "",
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "users.xlsx");
  };

  const handleExportToCSV = () => {
    try {
      const headers = [
        { label: "S.N.", key: "sn" },
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Public Address", key: "publicAddress" },
      ];

      const formattedData = users.map((user, index) => ({
        sn: index + 1,
        name: user?.name || "",
        email: user?.email || "",
        publicAddress: user?.evmWallet?.address || "",
      }));
      exportToCSV(formattedData, headers, "wallet_users.csv");
    } catch (error) {
      console.log("Failed to export to CSV : ", error.message);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0">Wallet User Management</h1>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  Download Report
                  <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                  <li
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#f8f9fa",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={exportToPDF}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#D3D3D3")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f8f9fa")
                    }
                  >
                    Download PDF
                  </li>
                  <li
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#f8f9fa",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={exportToExcel}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#D3D3D3")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f8f9fa")
                    }
                  >
                    Download Excel
                  </li>

                  <li
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#f8f9fa",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleExportToCSV}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#D3D3D3")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#f8f9fa")
                    }
                  >
                    Download CSV
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <input
                      type="text"
                      placeholder="Search users..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Public Address</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.length > 0 ? (
                          users.map((user, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{user?.name}</td>
                              <td>{user?.email}</td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: "none",
                                  }}
                                >
                                  {user?.evmWallet?.address}
                                  <button
                                    onClick={() =>
                                      copyToClipboard(user?.evmWallet?.address)
                                    }
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    <IoCopyOutline />
                                  </button>
                                </div>
                              </td>

                              <td>
                                <Link
                                  to={`/users-view/${user?._id}`}
                                  className="btn btn-link merch-view"
                                >
                                  <i className="fas fa-eye"></i>
                                </Link>
                                <Link
                                  to={`/users-edit/${user?._id}`}
                                  className="btn btn-link merch-edit"
                                >
                                  <i className="fas fa-edit"></i>
                                </Link>
                                {/* <button
                                  className={`btn btn-link ${
                                    user.isActive ? "text-success" : "text-danger"
                                  }`}
                                  onClick={() =>
                                    toggleUserStatus(user._id, user.isActive)
                                  }
                                >
                                  {user.isActive ? (
                                    <i className="fas fa-toggle-on"></i>
                                  ) : (
                                    <i className="fas fa-toggle-off"></i>
                                  )}
                                </button> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: "20px",
                                }}
                              >
                                <p>Data not found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletUser;
