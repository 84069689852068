import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import routes from "./App";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <App />
  <>
    <RouterProvider router={routes} />
    <ToastContainer />
  </>

  // <React.StrictMode>
  // </React.StrictMode>
);
