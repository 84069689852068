import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../../helper/helper";

const TronMerchantWithdrawFeeCard = () => {
  // Changed the name to uppercase
  const [formData, setFormData] = useState({
    tronPlatformFee: "",
    tronMerchantFee: "",
    tronAdminWallet: "",
  });
  const [errors, setErrors] = useState({});
  const [adminFeeInfo, setAdminFeeInfo] = useState(null); // Current fee from the server

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Ensure ValidateInputs is defined or imported
    const updatedErrors = await ValidateInputs(name, value, errors);
    setErrors(updatedErrors);
  };

  const fetchPlatformFee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-platform-fee`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response?.data?.data;
        setAdminFeeInfo(data);
        setFormData(data); // Set form fields with fetched data
      }
    } catch (error) {
      console.error("Error fetching Platform Fee:", error);
    }
  };

  const handleSetFee = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    // Check for errors before proceeding
    if (
      errors.tronPlatformFee ||
      errors.tronAdminWallet ||
      errors.tronMerchantFee
    ) {
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/update-platform-fee`,
        {
          tronPlatformFee: Number(formData.tronPlatformFee),
          tronMerchantFee: Number(formData.tronMerchantFee),
          tronAdminWallet: formData.tronAdminWallet,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Platform Fee updated successfully!");
        setAdminFeeInfo(formData); // Update the adminFeeInfo with the new data
      } else {
        toast.error("Failed to update the platform fee. Please try again.");
      }
    } catch (error) {
      console.log(
        "Error updating Platform Fee:",
        error?.response?.data?.message[0]
      );
      toast.error(error?.response?.data?.message[0]);
    }
  };

  useEffect(() => {
    fetchPlatformFee();
  }, []);

  return (
    <div className="col-md-4">
      <div className="card card-primary">
        <form id="quickForm" onSubmit={handleSetFee}>
          <div
            className="card-header"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h3 className="card-title">Tron Network Merchant & Platform Fee</h3>
          </div>

          <div className="card-body">
            <div className="form-group">
              <label htmlFor="tronPlatformFee">Platform Fee (%)</label>
              <input
                type="number"
                name="tronPlatformFee"
                className={`form-control ${
                  errors.tronPlatformFee ? "is-invalid" : ""
                }`}
                value={formData.tronPlatformFee}
                onChange={handleChange}
                placeholder="Enter platform fee in percent: 5%"
              />
              {errors.tronPlatformFee && (
                <div className="invalid-feedback">{errors.tronPlatformFee}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="tronMerchantFee">Merchant Fee (%)</label>
              <input
                type="number"
                name="tronMerchantFee"
                className={`form-control ${
                  errors.tronMerchantFee ? "is-invalid" : ""
                }`}
                value={formData.tronMerchantFee}
                onChange={handleChange}
                placeholder="Enter merchant fee in percent: 5%"
              />
              {errors.tronPlatformFee && (
                <div className="invalid-feedback">{errors.tronMerchantFee}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="tronAdminWallet">Admin Wallet Address</label>
              <input
                type="text"
                name="tronAdminWallet"
                className={`form-control ${
                  errors.tronAdminWallet ? "is-invalid" : ""
                }`}
                value={formData.tronAdminWallet}
                onChange={handleChange}
                placeholder="Enter admin wallet address"
              />
              {errors.tronAdminWallet && (
                <div className="invalid-feedback">{errors.tronAdminWallet}</div>
              )}
            </div>
          </div>
          <div
            className="card-footer"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              type="submit"
              className="btn btn-primary"
              disabled={adminFeeInfo === formData}
            >
              Set Fee & Address
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TronMerchantWithdrawFeeCard;
