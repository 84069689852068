import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../components/Loader";

const UpdateOtherCmsPages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({});
  const [pageName, setPageName] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const { pageId } = useParams();
  const navigate = useNavigate();

  const fetchPageDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/other-pages/view`,
        {
          params: { id: pageId },
        }
      );
      setPage(response?.data);
      setPageName(response?.data?.page?.name);

      setFormData({
        title: response?.data?.page?.title,
        description: response?.data?.page?.description || "",
      });
    } catch (error) {
      console.error("Error fetching page details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPageDetails();
  }, [pageId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange1 = (event, editor) => {
    const data = editor.getData();
    setFormData((prevData) => ({
      ...prevData,
      description: data,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData?.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_URL}/other-pages/update-api`,
        {
          id: pageId,
          ...formData,
        }
      );
      toast.success("Page details updated successfully!");
      navigate("/content-management");
    } catch (error) {
      console.error("Error updating content details:", error);
      alert("Failed to update content details.");
    } finally {
      setIsLoading(false);
    }
  };

  class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }
    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);
            console.log("formData", formData);

            axios
              .post(
                `${process.env.REACT_APP_API_URL}/pages/file-upload`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                resolve({
                  default: response?.data?.url,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
      );
    }

    abort() {}
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  if (isLoading) {
    return <Loader />;
  }

  console.log("errors", errors);
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{pageName}</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {page && page.page && (
                        <>
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className={`form-control ${
                                errors.title ? "is-invalid" : ""
                              }`}
                              placeholder="Enter title"
                              value={formData.title}
                              onChange={handleInputChange}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {errors.title}
                              </div>
                            )}
                          </div>

                          {formData && (
                            <div className="form-group">
                              <label htmlFor="description">Description</label>

                              <CKEditor
                                editor={ClassicEditor}
                                data={formData.description}
                                onChange={handleEditorChange1}
                                config={{
                                  extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                              />
                              {errors.description && (
                                <div className="invalid-feedback d-block">
                                  {errors.description}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateOtherCmsPages;
