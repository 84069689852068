import React from "react";
import EvmMerchantWithdrawFeeCard from "./Components/EvmMerchantWithdrawFeeCard";
import TronMerchantWithdrawFeeCard from "./Components/TronMerchantWithdrawFeeCard";
import BtcMerchantWithdrawFeeCard from "./Components/BtcMerchantWithdrawFeeCard";

const FeeManagement = () => {
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div
                className="col-sm-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <h1 className="m-0">Platform Fee Update/Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row">
                {/* First column for the form */}
                <EvmMerchantWithdrawFeeCard />

                {/* Second column for PaymentLinkFeeCard */}
                <TronMerchantWithdrawFeeCard />

                {/* Second column for PaymentLinkFeeCard */}
                <BtcMerchantWithdrawFeeCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeManagement;
