import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ValidateInputs } from "../../../helper/helper";
import Loader from "../../../components/Loader";

const BtcMerchantWithdrawFeeCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  // Changed the name to uppercase
  const [formData, setFormData] = useState({
    btcPlatformFee: "",
    btcMerchantFee: "",
    btcAdminWallet: "",
  });
  const [errors, setErrors] = useState({});
  const [adminFeeInfo, setAdminFeeInfo] = useState(null); // Current fee from the server

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Ensure ValidateInputs is defined or imported
    const updatedErrors = await ValidateInputs(name, value, errors);
    setErrors(updatedErrors);
  };

  const fetchPlatformFee = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-platform-fee`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response?.data?.data;
        setAdminFeeInfo(data);
        setFormData(data); // Set form fields with fetched data
      }
    } catch (error) {
      console.error("Error fetching Platform Fee:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetFee = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    // Check for errors before proceeding
    if (
      errors.btcPlatformFee ||
      errors.btcAdminWallet ||
      errors.btcMerchantFee
    ) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/update-platform-fee`,
        {
          btcPlatformFee: Number(formData.btcPlatformFee),
          btcMerchantFee: Number(formData.btcMerchantFee),
          btcAdminWallet: formData.btcAdminWallet,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Platform Fee updated successfully!");
        setAdminFeeInfo(formData); // Update the adminFeeInfo with the new data
      } else {
        toast.error("Failed to update the platform fee. Please try again.");
      }
    } catch (error) {
      console.error("Error updating Platform Fee:", error);
      toast.error("An error occurred while updating the Platform Fee.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlatformFee();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="col-md-4">
      <div className="card card-primary">
        <form id="quickForm" onSubmit={handleSetFee}>
          <div
            className="card-header"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h3 className="card-title">
              Bitcoin Network Merchant & Platform Fee
            </h3>
          </div>

          <div className="card-body">
            <div className="form-group">
              <label htmlFor="btcPlatformFee">Platform Fee (%)</label>
              <input
                type="number"
                name="btcPlatformFee"
                className={`form-control ${
                  errors.btcPlatformFee ? "is-invalid" : ""
                }`}
                value={formData.btcPlatformFee}
                onChange={handleChange}
                placeholder="Enter platform fee in percent: 5%"
              />
              {errors.btcPlatformFee && (
                <div className="invalid-feedback">{errors.btcPlatformFee}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="btcMerchantFee">Merchant Fee (%)</label>
              <input
                type="number"
                name="btcMerchantFee"
                className={`form-control ${
                  errors.btcMerchantFee ? "is-invalid" : ""
                }`}
                value={formData.btcMerchantFee}
                onChange={handleChange}
                placeholder="Enter merchant fee in percent: 5%"
              />
              {errors.btcPlatformFee && (
                <div className="invalid-feedback">{errors.btcMerchantFee}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="btcAdminWallet">Admin Wallet Address</label>
              <input
                type="text"
                name="btcAdminWallet"
                className={`form-control ${
                  errors.btcAdminWallet ? "is-invalid" : ""
                }`}
                value={formData.btcAdminWallet}
                onChange={handleChange}
                placeholder="Enter admin wallet address"
              />
              {errors.btcAdminWallet && (
                <div className="invalid-feedback">{errors.btcAdminWallet}</div>
              )}
            </div>
          </div>
          <div
            className="card-footer"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              type="submit"
              className="btn btn-primary"
              disabled={adminFeeInfo === formData}
            >
              Set Fee & Address
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BtcMerchantWithdrawFeeCard;
