import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import Login from "./view/Admin/Login";
import ViewAdmin from "./view/Admin/View";
import ContactUs from "./view/ContactUs/ContactUs";
import ViewContact from "./view/ContactUs/View";
import ContentManagement from "./view/ContentManagement/ContentManagement";
import UpdateContent from "./view/ContentManagement/UpdateContent";
import Dashboard from "./view/Admin/Dashboard";
import AddFaq from "./view/Faq/AddFaq";
import Faq from "./view/Faq/Faq";
import UpdateFaq from "./view/Faq/UpdateFaq";
import AddInquiry from "./view/Inquiry/AddInquiry";
import CreateAccount from "./view/Inquiry/CreateAccount";
import Inquiry from "./view/Inquiry/Inquiry";
import ViewInquiry from "./view/Inquiry/ViewInquiry";
import Merchant from "./view/MerchantModule/Merchant";
import UpdateMerchant from "./view/MerchantModule/UpdateMerchant";
import ViewMerchant from "./view/MerchantModule/ViewMerchant";
import ViewApp from "./view/MerchantAppsModules/ViewApp";
import NotificationManagement from "./view/NotificationManagement";
import WalletUser from "./view/UserModule/WalletUser";
import WalletUserEdit from "./view/UserModule/WalletUserEdit";
import WalletUserView from "./view/UserModule/WalletUserView";
import AdminLayout from "./view/shared/AdminLayout";
import UnAuthLayout from "./view/shared/UnAuthLayout";
import UnauthenticatedRoute from "./view/shared/UnauthenticatedRoute";
import PrivateRoute from "./view/shared/PrivateRoute";
import UpdateOtherCmsPages from "./view/ContentManagement/UpdateOtherCmsPages";
import ChangePassword from "./view/Admin/ChangePassword";
import ResetPassword from "./view/Admin/ResetPassword";
import ForgotPassword from "./view/Admin/ForgotPassword";
import UpdatePricingPage from "./view/ContentManagement/UpdatePricingPage";
import UpdateHowItWorks from "./view/ContentManagement/UpdateHowItWorks";
import UpdateTestimonial from "./view/ContentManagement/UpdateTestimonial";
import FeeManagement from "./view/PaymentManagement/FeeManagement";
import CustmerSupport from "./view/CustomerSupport/CustomerSupport";
import UpdateCustomerSupport from "./view/CustomerSupport/UpdateCustomerSupport";
import UpdateContactUs from "./view/ContactUs/UpdateContactUs";
import TransactionManagement from "./view/TransactionManagement/TransactionManagement";
import WithdrawalLimit from "./view/PaymentManagement/WithdrawalLimit";
import PaymentManagement from "./view/PaymentManagement/PaymentManagement";
import ViewPaymentLink from "./view/PaymentManagement/ViewPaymentLink";
import AddToken from "./view/PaymentManagement/AddToken";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      //admin route
      {
        path: "/",
        element: <Navigate to={"/dashboard"} />,
      },
      {
        path: "/dashboard",
        element: <PrivateRoute component={<Dashboard />} />,
      },
      {
        path: "/admin-view",
        element: <PrivateRoute component={<ViewAdmin />} />,
      },
      {
        path: "/admin-change-password",
        element: <PrivateRoute component={<ChangePassword />} />,
      },

      //users route
      {
        path: "/users",
        element: <PrivateRoute component={<WalletUser />} />,
      },
      {
        path: "/users-view/:userId",
        element: <PrivateRoute component={<WalletUserView />} />,
      },
      {
        path: "/users-edit/:userId",
        element: <PrivateRoute component={<WalletUserEdit />} />,
      },

      //merchant route
      {
        path: "/merchants",
        element: <PrivateRoute component={<Merchant />} />,
      },
      {
        path: "/merchants-view/:userId",
        element: <PrivateRoute component={<ViewMerchant />} />,
      },
      {
        path: "/merchants-apps-view/:userId",
        element: <PrivateRoute component={<ViewApp />} />,
      },
      {
        path: "/view-payment-link/:paymentId",
        element: <PrivateRoute component={<ViewPaymentLink />} />,
      },
      {
        path: "/merchants-edit/:userId",
        element: <PrivateRoute component={<UpdateMerchant />} />,
      },
      {
        path: "/view-payment-link/:paymentId",
        element: <PrivateRoute component={<ViewPaymentLink />} />,
      },

      // inquiry route
      {
        path: "/inquiry",
        element: <PrivateRoute component={<Inquiry />} />,
      },
      {
        path: "/inquiry-view/:userId",
        element: <PrivateRoute component={<ViewInquiry />} />,
      },
      {
        path: "/inquiry-create-account/:userId",
        element: <PrivateRoute component={<CreateAccount />} />,
      },
      {
        path: "/inquiry-add",
        element: <PrivateRoute component={<AddInquiry />} />,
      },

      //cms route
      {
        path: "/content-management",
        element: <PrivateRoute component={<ContentManagement />} />,
      },
      {
        path: "/content-management-update/:pageId",
        element: <PrivateRoute component={<UpdateContent />} />,
      },

      {
        path: "/content-management-update-other/:pageId",
        element: <PrivateRoute component={<UpdateOtherCmsPages />} />,
      },

      {
        path: "/content-management-pricing-page/:pageId",
        element: <PrivateRoute component={<UpdatePricingPage />} />,
      },

      {
        path: "/content-management-how-it-works/:pageId",
        element: <PrivateRoute component={<UpdateHowItWorks />} />,
      },

      {
        path: "/content-management-testimonial/:pageId",
        element: <PrivateRoute component={<UpdateTestimonial />} />,
      },

      // /faq route
      {
        path: "/faq",
        element: <PrivateRoute component={<Faq />} />,
      },
      {
        path: "/faq-add",
        element: <PrivateRoute component={<AddFaq />} />,
      },
      {
        path: "/faq-update/:faqId",
        element: <PrivateRoute component={<UpdateFaq />} />,
      },

      // Contact us route
      {
        path: "/contact-us",
        element: <PrivateRoute component={<ContactUs />} />,
      },
      {
        path: "/contact-us-view/:userId",
        element: <PrivateRoute component={<ViewContact />} />,
      },

      {
        path: "/contact-us-update/:userId",
        element: <PrivateRoute component={<UpdateContactUs />} />,
      },

      // transaction management
      {
        path: "/transaction",
        element: <PrivateRoute component={<TransactionManagement />} />,
      },

      // payment management
      {
        path: "/payment-management",
        element: <PrivateRoute component={<PaymentManagement />} />,
      },
      {
        path: "/fee-management",
        element: <PrivateRoute component={<FeeManagement />} />,
      },

      //extension customer support
      {
        path: "/customer-support",
        element: <PrivateRoute component={<CustmerSupport />} />,
      },
      {
        path: "/customer-support-view/:userId",
        element: <PrivateRoute component={<UpdateCustomerSupport />} />,
      },

      {
        path: "/withdrawal-limit",
        element: <PrivateRoute component={<WithdrawalLimit />} />,
      },

      {
        path: "/withdrawal-limit-add-token",
        element: <PrivateRoute component={<AddToken />} />,
      },

      {
        path: "/notification-management",
        element: <PrivateRoute component={<NotificationManagement />} />,
      },

      {
        path: "/logout",
        element: <PrivateRoute component={<Login />} />,
      },

      //APPS
      {
        path: "/logout",
        element: <PrivateRoute component={<Login />} />,
      },
    ],
  },
  {
    path: "/",
    element: <UnAuthLayout />,
    children: [
      {
        path: "/login",
        element: <UnauthenticatedRoute component={Login} />,
      },
      {
        path: "/reset-password",
        element: <UnauthenticatedRoute component={ResetPassword} />,
      },
      {
        path: "/forget-password",
        element: <UnauthenticatedRoute component={ForgotPassword} />,
      },
    ],
  },
]);

export default routes;
